.box-card[data-v-fa822b0e] .card-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  padding: 0;
}
.header[data-v-fa822b0e] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}
.header .header-lft[data-v-fa822b0e] {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.header .header-lft .icon[data-v-fa822b0e] {
  margin-right: 7px;
}
.header .header-lft .desc[data-v-fa822b0e] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.header .status[data-v-fa822b0e] {
  flex-shrink: 0;
  margin-left: 50px;
}
.content[data-v-fa822b0e] {
  display: flex;
  justify-content: space-between;
}
